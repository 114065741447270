import { enhancePrisma } from "blitz"
import { PrismaClient, Prisma } from "@prisma/client"

const DEBUG = process.env.PRISMA_DEBUG === "true"

// https://blitzjs.com/docs/prisma#enhance-prisma-prisma-client
// - It ensures you only have one instance of Prisma Client in your application to reduce the number of database connections. But in serverless deployment, you will still have one instance per serverless function.
// - It ensures Prisma client is not used in your client bundle.
// - It adds a db.$reset() => Promise<void> function for use in tests
// - It automatically closes the database connection at the end of your tests if you are using the Blitz vitest preset
const EnhancedPrisma = enhancePrisma(PrismaClient)

const db = new EnhancedPrisma(
  DEBUG
    ? {
        log: [
          {
            emit: "event",
            level: "query",
          },
        ],
      }
    : {}
)

// Blitz `enhancePrisma` - prisma listener is only available on the server
if (typeof window === "undefined" && DEBUG) {
  db.$on("query", async (originalEvent) => {
    const event = originalEvent as unknown as Prisma.QueryEvent // Blitz `enhancePrisma` changes the type of the event to Prisma.LogEvent
    console.log(`${event.query} ${event.params}`)
  })
}

export * from "@prisma/client"

export default db
